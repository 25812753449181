import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
// material
import {
  Link,
  Grid,
  Stack,
  Button,
  Card,
  Table,
  Typography,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Tabs, Tab
} from '@mui/material';


import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
import { APPmileage, AppNewUsers, AppEvent, AppCarnum } from '../components/_dashboard/app';
// eslint-disable-next-line import/named
import { CarListToolbar } from '../components/_dashboard/user';
import { LeonTagRecord, TruckRecord, DriverRecord, ReaderRecord, CmsRecord, UserRecord } from '../components/record/index';

import { fetchUserOperationRecord } from "../actions";
import TTable from '../components/TTable'
import * as config from "../config/config";

// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};


export default function EnhancedTable() {
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:

  const headCells = [
    {
      id: 'user_name',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '使用者'
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '操作',
      type: 'format',
      fn: (type) => {
        let content = type;
        let bColor = '';
        let color = '';
        if (type === 'update') {
          color = '#B78103'
          bColor = '#FFC10729'
          content = '修改'
        }
        else if (type === 'insert') {
          color = '#00AB55'
          bColor = '#07ff1529'
          content = '新增'
        }
        else if (type === 'login') {
          color = '#1e22ff'
          bColor = '#9192ff55'
          content = '登入'
        }

        return (
          <div style={{
            'background-color': bColor, 'padding': '5px 0px 5px 0px', 'border-radius': 10
          }}>
            <a style={{ color: color }} >
              {content}
            </a>
          </div>

        )
      }

    },
    {
      id: 'type',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '操作類別',
      type: 'format',
      fn: (type) => {
        if (type === 'Truck')
          return '車輛資訊'
        if (type === 'Driver')
          return '車輛資訊'
        if (type === 'LeonTag')
          return '識別裝置資訊'
        if (type === 'User')
          return '使用者資訊'
        if (type === 'Reader')
          return '智慧感知讀取器'
        if (type === 'Cms')
          return '資訊可變標誌'
        return type
      }
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '操作紀錄'
    },
    {
      id: 'updateTime',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '時間',
      type: 'format',
      fn: (data) => {

        const currentdate = new Date();
        const datetime = `${currentdate.getFullYear()}/${currentdate.getMonth() + 1}/${currentdate.getDate()} 00:00:00`
        const utc0 = new Date(datetime).toISOString().slice(0, 19).replace('T', ' ')
        const difference = ((new Date(datetime).getTime() - new Date(utc0).getTime()) / (1000 * 60 * 60));
        const time = new Date(moment(data).add(difference, 'hours')).toLocaleString()
        return (
          time
        )
      }
    },
    {
      type: 'render',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '詳細資料',
      fn: (row) => {
        const { type, action, description } = row
        if (description === '修改密碼')
          return (null)
        if (action === 'login')
          return (null)
        return (
          <Button variant="outlined" onClick={
            () => {
              // alert(leonTagID)
              setInfo(row.id, type, action);
              // console.log(type)
              // setValue(1)
            }
          }>
            詳細資訊
          </Button>
        )
      }
    }
  ];

  const [userOperationRecordList, setuserOperationRecordList] = React.useState([]);
  const [driverlist, setdriverlist] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('');
  const [action, setAction] = React.useState('');
  // eslint-disable-next-line prefer-const

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const userOperationRecordStore = useSelector((state) => state.userOperationRecord);
  const { users } = usersStore;
  const { userOperationRecord } = userOperationRecordStore;

  // let intervalId = null;
  useEffect(() => {
    // getdriverlist();
    // // 定時讀取車機清單
    // // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   getdriverlist();
    // }, 25000);
    // // componentWillUnmount
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
    // if(error === "FAILED")    
    //   window.location.href = '/login';
    if (userOperationRecord.length === 0) {
      getdriverlist();
      setuserOperationRecordList([]);
      setuserOperationRecordList(userOperationRecord);
    }
    else {
      setuserOperationRecordList([]);
      setuserOperationRecordList(userOperationRecord);
    }
    // console.log(userOperationRecord);
  }, [userOperationRecord, users]);
  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const getdriverlist = async () => {

    // console.log(users);
    // console.log(1);
    dispatch(fetchUserOperationRecord({ "userName": users.userName, "token": users.token }));

    setuserOperationRecordList(userOperationRecord);
    // console.log(userOperationRecord);
  };


  const [operationRecordID, setOperationRecordID] = React.useState(0);

  const setInfo = (id, dataType, action) => {
    setType(dataType);
    setOperationRecordID(id);
    setAction(action);
    setValue(1);
  };


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      'style':{'font-size':'24px'},
    };
  }
  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="智慧管理監控平台">
      <Container maxWidth="xl">

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="操作紀錄" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
        <Grid item xs={12}
            sx={{
              '& th': {
                fontSize: config.tableTitleFontSize
              },
              '& td': {
                fontSize: config.tableContentFontSize
              },
              '& span': {
                fontSize: config.tableContentFontSize,
                height: config.tableSpanHeight
              }
            }}>
            <TTable Vehiclelist={userOperationRecordList} headCells={headCells} />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {
            (() => {
              if (type === "LeonTag") {
                return (<LeonTagRecord operationRecord_id={operationRecordID} action={action} />);
              }
              if (type === "Reader") {
                return (<ReaderRecord operationRecord_id={operationRecordID} action={action} />);
              }
              if (type === "Truck") {
                return (<TruckRecord operationRecord_id={operationRecordID} action={action} />);
              }
              if (type === "Driver") {
                return (<DriverRecord operationRecord_id={operationRecordID} action={action} />);
              }
              if (type === "Cms") {
                return (<CmsRecord operationRecord_id={operationRecordID} action={action} />);
              }
              if (type === "User") {
                return (<UserRecord operationRecord_id={operationRecordID} action={action} />);
              }
              return (null);
            })()
          }
        </TabPanel>
      </Container>
    </Page>
  );
}
