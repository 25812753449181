// ----------------------------------------------------------------------

const account = {
  organization: '新北市政府交通局',
  organizationen: 'Bureau of Transportation, Tainan City Government',
  displayName: '智慧管理監控平台',
  email: 'demo@minimals.cc'
};

export default account;
