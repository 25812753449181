import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Stack,
  Card,
  Table,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import L from 'leaflet';
import MuiAlert from '@mui/material/Alert';
import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// eslint-disable-next-line import/named
import { CarListToolbar, abnormalCarListToolbar } from '../components/_dashboard/user';
import { SimpleDialog } from '../components/dialog';

import { fetchTruckList, fetchDriverList, setAlertMessage } from "../actions";
import TTable from '../components/TTable'

import { TruckForm } from '../components/editData/index';

import * as config from "../config/config";



const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));


// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'style': { 'font-size': '24px' },
  };
}

export default function EnhancedTable() {

  const headCells = [
    {
      // 排序id要跟資料欄一樣名稱
      id: 'rownum',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '項目'
    },
    {
      id: 'enable',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '狀態',
      type: 'format',
      fn: (enable) => {
        const enableStr = enable ? '啟用' : '停用';
        return (
          enableStr
        )
      }
    },
    {
      id: 'licensePlateNumber',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '車牌'
    },
    {
      id: 'truckColor',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '車輛顏色'
    },
    {
      id: 'tonnage',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '車輛噸數'
    },
    {
      id: 'truckType',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '車輛種類'
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '公司名稱'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '描述',
    },
    {
      id: 'useType',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '使用類別'
    },
    {
      id: 'specialMark',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '特殊註記',
      type: 'format',
      fn: (data) => {
        if (data !== '' && data !== null) {
          return (
            <Label
              variant="ghost"
              color="error"
            >
              {data}
            </Label>
          )
        }
      }
    },
    {
      id: 'updateTime',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '更新時間',
      type: 'format',
      fn: (data) => {
        const time = new Date(data).toLocaleString()
        return (
          time
        )
      }
    },
    {
      type: 'render',
      fn: (row) => {
        const { licensePlateNumber, tonnage, height, brand, truckType, truckColor, specialMark, useType, riskEvaluation, DateOfManufacture, description, updateTime, enable } = row
        const dateOfManufactureTime = new Date(DateOfManufacture).toLocaleDateString()
        // const updateTime1 = new Date(updateTime).toLocaleString()   
        return (
          <Button variant="outlined" onClick={
            () => {
              const tonnage1 = (tonnage === null || tonnage === '' || tonnage === undefined) ? '' : parseFloat(tonnage);
              const height1 = (height === null || height === '' || height === undefined) ? '' : parseFloat(height);
              const spMarkInfoid = (row.specialMark_info_id === null || row.specialMark_info_id === '' || row.specialMark_info_id === undefined) ? [] : JSON.parse(row.specialMark_info_id);
              setInfo(row.id, row.driver_id, row.team_id, licensePlateNumber, tonnage1, height1, brand, truckType, truckColor, description, specialMark, spMarkInfoid, useType, riskEvaluation, dateOfManufactureTime, updateTime, enable);
              // // console.log(leonTagID)
              // setValue(1)
            }
          }>
            編輯
          </Button>
        )
      }
    }
  ];
  // 宣告一個新的 state 變數，我們稱作為「count」。
  // 相似於 componentDidMount 和 componentDidUpdate:
  const Region = "彰化縣";
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  // 車機清單
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [reason, setreason] = React.useState(' ');
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [marker, setMarker] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);
  const { users } = usersStore;
  const trucksStore = useSelector((state) => state.truckManage);
  const { trucks, error } = trucksStore;
  const teamsStore = useSelector((state) => state.teamsInfo);
  const { teams } = teamsStore;
  // const { loading, trucks, error } = trucksStore;    
  // eslint-disable-next-line prefer-const
  let intervalId = null;
  let map;

  useEffect(() => {
    // if (error === "FAILED")
    //   window.location.href = '/login';
    // if (users.resultCode !== "Success")
    //   window.location.href = '/login';
    // else
    //   dispatch(fetchTeams({ "token": users.token }));
    if (trucks.length === 0) {
      Vehicle_management();
      setVehiclelist([]);
      setVehiclelist(trucks);
      // console.log(trucks);
      // console.log(teams);
    }
    else {
      setVehiclelist([]);
      setVehiclelist(trucks);
      // console.log(trucks);
      // console.log(teams);
    }
    // 定時讀取車機清單
    // eslint-disable-next-line no-const-assign
    // intervalId = setInterval(() => {
    //   Vehicle_management();
    // }, 50000);
    // componentWillUnmount
    // return function cleanup() {
    //   clearInterval(intervalId);
    // };
  }, [trucks, error, users]);

  // 讀取車機清單
  // eslint-disable-next-line camelcase
  const Vehicle_management = async () => {
    // const result = fetchTrucks({"username": users.username, "token": users.token});
    // console.log(users);
    // console.log(1);
    dispatch(fetchTruckList({ "userName": users.userName, "token": users.token }));
    dispatch(fetchDriverList({ "userName": users.userName, "token": users.token }));

    //   const ApiUrl = axios.create({
    //     baseURL: `https://${ipconfig.apiIP}/api/v1/truck`,
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",     

    //         'Access-Control-Allow-Origin' : '*',
    //         'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',   
    //     }
    //   });
    //   ApiUrl.post('/list', {"username": users.username, "token": users.token} )
    //   // ApiUrl.post('/list', {"username": localStorage.UserName, "token": localStorage.token} )
    //   .then((response) => {       
    //       // console.dir(typeof response.data);
    //       if (response.data.data !== []) {
    //           setVehiclelist([]);
    //           setVehiclelist(response.data.data);
    //           // console.log(response.data.data)
    //         }else
    //         {
    //           setVehiclelist([]);
    //         }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   })

  };

  // 關閉彈跳釋出
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  // tab切換
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [driverID, setDriverID] = useState(0);
  const [truckID, setTruckID] = useState(0);
  const [teamID, setTeamID] = useState(0);
  const [type, setType] = useState();

  const [licensePlateNumber, setLicensePlateNumber] = useState();
  const [tonnage, setTonnage] = useState();
  const [height, setHight] = useState();
  const [brand, setBrand] = useState();
  const [truckType, setTruckType] = useState();
  const [truckColor, setTruckColor] = useState();
  const [specialMark, setSpecialMark] = useState();
  const [specialMarkInfoID, setspecialMarkInfoID] = useState();
  const [riskEvaluation, setRiskEvaluation] = useState();
  const [dateOfManufacture, setDateOfManufacture] = useState();
  const [description, setDescription] = useState();
  const [useType, setuseType] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [enable, setEnable] = useState(1);

  const setInfo = (truckID, driverID, teamID, licensePlateNumber, tonnage, height, brand, truckType, truckColor, description, specialMark, specialMarkInfoID, use, riskEvaluation, dateOfManufacture, updateTime, enable) => {
    setTruckID(truckID);
    setDriverID(driverID);
    setTeamID(teamID);
    setLicensePlateNumber(licensePlateNumber);
    setTonnage(tonnage);
    setHight(height);
    setBrand(brand);
    setTruckType(truckType);
    setTruckColor(truckColor);
    setDescription(description);
    setSpecialMark(specialMark);
    setspecialMarkInfoID(specialMarkInfoID);
    setuseType(use);
    setRiskEvaluation(riskEvaluation);
    setDateOfManufacture(dateOfManufacture);
    setUpdateTime(updateTime)
    setEnable(enable);
    setType('update');
    setValue(1);
  };

  const handleDialogClickOpen = (leonTagID, driverID, truckID, truckDynamicID) => {
    setDialogOpen(true);
    setDriverID(driverID);
    setTruckID(truckID);
  };

  const handleDialogClose = (value) => {
    setDialogOpen(false);
    setType(value);
    if (value !== '')
      setValue(1);
  };

  // const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [content, setContent] = React.useState("");

  const insetTruckForm = () => {
    setTruckID(0);
    setDriverID(0);
    setTeamID(users.team_id);
    setLicensePlateNumber('');
    setTonnage('');
    setHight('');
    setBrand('');
    setTruckType('');
    setTruckColor('');
    setDescription('');
    setSpecialMark('');
    setspecialMarkInfoID([]);
    setuseType('營業用');
    setRiskEvaluation('');
    setDateOfManufacture('');
    setUpdateTime('')
    setEnable(enable);
    setType('insert');
    setValue(1);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Page title="智慧管理監控平台">
      <Container maxWidth="xl">
        {/* <CustomizedSnackbars 
          open={open}
          severity={severity}
          content={content}
        /> */}
        {/* <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {reason}
          </Alert>
        </Snackbar> */}
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="車輛資訊" {...a11yProps(0)} />
          <Tab label="詳細資訊" {...a11yProps(1)} />
          <Button variant="contained" size="large" style={{ marginLeft: "auto", marginRight: "24px" }}
            onClick={insetTruckForm}
          >新增</Button>
        </Tabs>

        <TabPanel value={value} index={0}>



          <Grid item xs={12}
            sx={{
              '& th': {
                fontSize: config.tableTitleFontSize
              },
              '& td': {
                fontSize: config.tableContentFontSize
              },
              '& span': {
                fontSize: config.tableContentFontSize,
                height: config.tableSpanHeight
              }
            }}>
            <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
          </Grid>




        </TabPanel>

        <TabPanel value={value} index={1}>
          <TruckForm
            driverID={driverID}
            truckID={truckID}
            teamID={teamID}
            type={type}
            licensePlateNumber={licensePlateNumber}
            tonnage={tonnage}
            height={height}
            brand={brand}
            truckType={truckType}
            truckColor={truckColor}
            description={description}
            specialMark={specialMark}
            specialMarkInfoID={specialMarkInfoID}
            useType={useType}
            riskEvaluation={riskEvaluation}
            dateOfManufacture={dateOfManufacture}
            updateTime={updateTime}
            enable={enable}
            setValue={setValue}
          />
          {/* {
            (() => {
              if (type === "LeonTag") {
                return (<LeonTagRecord operationRecord_id={leonTagID} />);
              }
              if (type === "Truck") {
                return (<TruckRecord operationRecord_id={truckID} />);
              }
              if (type === "Driver") {
                return (<DriverRecord operationRecord_id={driverID} />);
              }
            })()
          } */}
        </TabPanel>
        <SimpleDialog
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      </Container>
    </Page>
  );
}
