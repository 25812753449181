import React, { useEffect, useState } from 'react';
import { startOfMonth } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TextField,
  Grid,
  Modal
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getCctvImages, getMonthlyEventAggregation } from '../services/cctvService';
import { apiIP } from '../config/ipconfig';

const CctvImageList = ({ selectedCctv, onImageSelect, images, setImages }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchImages = async () => {
      if (selectedCctv) {
        setLoading(true);
        try {
          const data = await getCctvImages(selectedCctv.code, page + 1, rowsPerPage, selectedDate);
          setImages(data.images);
          console.log('data:', data);
          setTotalImages(data.pagination.totalImages);
        } catch (error) {
          console.error('Error fetching CCTV images:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchImages();
  }, [selectedCctv, page, selectedDate]);

  useEffect(() => {
    if (selectedCctv) {
      fetchstats(selectedDate);
    }
  }, [selectedCctv]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    if (selectedCctv) {
      fetchstats(date);
    }
  };

  const fetchstats = async (date) => {
    try {
      const monthStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      const stats = await getMonthlyEventAggregation(selectedCctv.code, monthStr);
      console.log('stats:', stats);
      setMonthlyStats(stats);
    } catch (error) {
      console.error('Error fetching monthly stats:', error);
    }
  };

  const handleSearch = () => {
    setPage(0); // 重置頁面為第一頁
    // 觸發 useEffect 重新加載圖片
  };

  // 修改圖表資料處理函數
  const processChartData = () => {
    // 取得當月所有日期
    const daysInMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    ).getDate();

    // 建立所有日期的映射，預設值為0
    const dailyCount = {};
    for (let i = 1; i <= daysInMonth; i += 1) {
      const date = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        i
      ).toLocaleDateString('zh-TW');
      dailyCount[date] = 0;
    }

    // 填入實際統計數據
    if (monthlyStats.dailyStats) {
      monthlyStats.dailyStats.forEach((stat) => {
        const date = new Date(stat.date).toLocaleDateString('zh-TW');
        dailyCount[date] = stat.count;
      });
    }

    // 轉換為圖表所需格式
    return Object.entries(dailyCount).map(([date, count]) => ({
      date,
      count
    }));
  };

  // 下載 CSV
  const handleDownloadCSV = () => {
    let csvData = [];

    if (monthlyStats.dailyStats) {
      // 使用與圖表相同的資料處理方式
      const chartData = processChartData();
      csvData = chartData;
    }

    const BOM = '\uFEFF';
    const csvContent =
      BOM +
      [['日期', '影像數量'], ...csvData.map((item) => [item.date, item.count])]
        .map((row) => row.join(','))
        .join('\n');

    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `cctv_stats_${selectedCctv.code}_${selectedDate.toLocaleDateString()}.csv`;
    link.click();
  };

  const handleOpenModal = (image) => {
    setModalImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalImage(null);
  };

  if (!selectedCctv) {
    return <Typography>請選擇一個 CCTV</Typography>;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            {selectedCctv.name} 資料列表
          </Typography>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhCN}>
            <DatePicker
              views={['year', 'month']}
              label="選擇月份"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Button variant="contained" startIcon={<FileDownloadIcon />} onClick={handleDownloadCSV}>
            下載統計資料
          </Button>
        </Grid>
      </Grid>

      <TableContainer sx={{ flexGrow: 1 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>時間</TableCell>
              <TableCell>檔案名稱</TableCell>
              <TableCell>縮圖</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              images.map((image) => (
                <TableRow key={image.id} hover style={{ cursor: 'pointer' }}>
                  <TableCell>
                    {new Date(image.timestamp).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })}
                  </TableCell>
                  <TableCell>{image.filename}</TableCell>
                  <TableCell>
                    <button
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'zoom-in' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenModal(image);
                      }}
                    >
                      <img
                        src={`https://${apiIP}/api/v1/cctv/images/${image.location}/${image.camera}/${image.date}/thumbnails/${image.filename}`}
                        alt="CCTV"
                        style={{ width: '100px', height: 'auto' }}
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* 新增放大圖片的 Modal */}
      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflowY: 'auto',
            outline: 'none'
          }}
        >
          {modalImage && (
            <>
              <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                {modalImage.filename}
              </Typography>
              <img
                src={`https://${apiIP}/api/v1/cctv/images/${modalImage.location}/${modalImage.camera}/${modalImage.date}/original/${modalImage.filename}`}
                alt="CCTV 原始圖片"
                style={{
                  maxWidth: '100%',
                  maxHeight: 'calc(90vh - 60px)',
                  objectFit: 'contain'
                }}
              />
            </>
          )}
        </Box>
      </Modal>

      <Box sx={{ height: '40px' }}>
        {' '}
        {/* 調整高度 */}
        <TablePagination
          component="div"
          count={totalImages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10]}
          sx={{ minHeight: '52px' }}
        />
      </Box>
      <Box sx={{ width: '100%', mb: 2 }}>
        <BarChart
          width={900}
          height={200}
          data={processChartData()}
          margin={{
            top: 20,
            right: 30,
            left: 40,
            bottom: 40
          }} /* 增加 bottom margin 留出顯示空間 */
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date">
            <Label value="時間(日期)" position="insideBottom" offset={-20} />
          </XAxis>
          <YAxis>
            <Label value="數量(次)" angle={-90} position="insideLeft" dx={-10} />
          </YAxis>
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" name="影像數量" />
        </BarChart>
      </Box>
    </Box>
  );
};

export default CctvImageList;
