import React, { useMemo } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// pages
import {
  TrafficIncidentOverviewDashboard,
  DashboardApp,
  DashboardAppTest,
  ReaderCountMap1,
  Truckservice,
  WarnNotification,
  SpecialMarkMap,
  Parking,
  TrafficAnalyze1,
  TurningTrafficAnalyze1,
  TravelSpeedAnalyze1,
  TruckLimitAnalyze,
  WarnNotificationAnalyze,
  SpecialMarkAnalyze,
  ParkingAnalyze,
  CompoundAnalyze,
  EquipmentDashboardApp,
  CCTVManage,
  CmsManage,
  ReplenishReaderData,
  UserManage,
  UserOperationRecord,
  LeonTagManage,
  TruckManage,
  DriverManage,
  TestRealTimeData,
  Uploadexcel,
  TestRawData,
  Login,
  NotFound,
  Overview,
  ReaderManage,
  Monitoring,
  AnalysisDashboard
} from './pages';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Router() {
  const query = useQuery();

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Monitoring /> } // 修改這裡
      ]
    },
    {
      path: '/info',
      element: <DashboardLayout />,
      children: [
        { path: 'TrafficService', element: <ReaderCountMap1 /> },
        { path: 'Truckservice', element: <Truckservice /> },
        { path: 'AlertReminder', element: <WarnNotification /> },
        { path: 'SpecialEvent', element: <SpecialMarkMap /> },
        { path: 'Parking', element: <Parking /> }
      ]
    },
    {
      path: '/analysis',
      element: <DashboardLayout />,
      children: [
        { path: 'TrafficFlow', element: <TrafficAnalyze1 /> },
        { path: 'TurningTraffic', element: <TurningTrafficAnalyze1 /> },
        { path: 'TravelSpeed', element: <TravelSpeedAnalyze1 /> },
        { path: 'TruckViolation', element: <TruckLimitAnalyze /> },
        { path: 'AlertReminder', element: <WarnNotificationAnalyze /> },
        { path: 'SpecialEvent', element: <SpecialMarkAnalyze /> },
        { path: 'Parking', element: <ParkingAnalyze /> },
        { path: 'Compound', element: <CompoundAnalyze /> }
      ]
    },
    {
      path: '/equipment',
      element: <DashboardLayout />,
      children: [
        { path: 'ConnectionStatus', element: <EquipmentDashboardApp /> },
        { path: 'CCTVManage', element: <CCTVManage /> },
        { path: 'CmsManage', element: <CmsManage /> },
        { path: 'ReaderManage', element: <ReaderManage /> }        
        //  path: 'AnalysisDashboard', element: <AnalysisDashboard /> }
      ]
    },
    {
      path: '/AnalysisDashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <AnalysisDashboard /> } // 2期Dashboard分析資料放此
      ]
    },
    {
      path: '/account',
      element: <DashboardLayout />,
      children: [
        { path: 'userManage', element: <UserManage /> },
        { path: 'userOperationRecord', element: <UserOperationRecord /> }
      ]
    },
    {
      path: '/manage',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/truckManage" replace /> },
        { element: <Navigate to="/overview" replace /> },
        { path: 'overview', element: <Overview /> },
        { path: 'leonTagManage', element: <LeonTagManage /> },
        { path: 'truckManage', element: <TruckManage /> },
        { path: 'driverManage', element: <DriverManage /> },
        { path: 'testRealTimeData', element: <TestRealTimeData /> },
        { path: 'Uploadexcel', element: <Uploadexcel /> },
        { path: 'RawData', element: <TestRawData /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default Router;
