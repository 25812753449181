import React, { useRef, useEffect, useCallback, useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as cocossd from '@tensorflow-models/coco-ssd';

// 節流函數
const throttle = (func, limit) => {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const classNameMapping = {
  person: '人',
  bicycle: '自行車',
  car: '汽車',
  motorcycle: '機車',
  bus: '公車',
  truck: '卡車',
  dog: '狗',
  cat: '貓'
  // 可以依需求增加更多對照
};

const LiveImageCanvas = ({
  cctvInfo,
  isPlaying,
  setIsPlaying,
  setLoading,
  setError,
  setMessage,
  DetectObjectsEnable
}) => {
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [model, setModel] = useState(null);
  const [isDetecting, setIsDetecting] = useState(false);
  const [detectionInterval, setDetectionInterval] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const stopStream = useCallback(() => {
    setIsPlaying(false);

    if (imgRef.current) {
      imgRef.current.src = '';
    }

    if (detectionInterval) {
      clearInterval(detectionInterval);
      setDetectionInterval(null);
    }

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    setMessage('串流已停止');
  }, [detectionInterval, timeoutId]);

  const detectObjects = useCallback(async () => {
    if (!model || !imgRef.current || !canvasRef.current || isDetecting) {
      return;
    }

    try {
      setIsDetecting(true);
      const img = imgRef.current;

      if (!img.complete || img.width === 0 || img.height === 0) {
        return;
      }

      const predictions = await model.detect(img);

      requestAnimationFrame(() => drawPredictions(predictions));
    } catch (err) {
      console.error('物件偵測錯誤:', err);
      setError(`物件偵測失敗: ${err.message}`);
    } finally {
      setIsDetecting(false);
    }
  }, [model]);

  const startStream = useCallback(() => {
    if (!imgRef.current || !cctvInfo?.dns_url) return;

    setLoading(true);
    setError(null);
    setIsPlaying(true);
    setMessage('');

    imgRef.current.crossOrigin = 'anonymous';
    const streamUrl = `${cctvInfo.dns_url}?_t=${new Date().getTime()}`;
    imgRef.current.src = streamUrl;

    if (DetectObjectsEnable) {
      const interval = setInterval(() => {
        if (!isDetecting) {
          detectObjects();
        }
      }, 500);
      setDetectionInterval(interval);
    }

    const newTimeoutId = setTimeout(() => {
      stopStream();
      setMessage('串流已自動停止');
    }, 60000);
    setTimeoutId(newTimeoutId);
  }, [cctvInfo, detectObjects, isDetecting, DetectObjectsEnable, stopStream]);

  useEffect(() => {
    if (isPlaying) {
      stopStream();
    }
    setTimeout(() => {
      startStream();
    }, 2000);
  }, [cctvInfo?.dns_url]);

  useEffect(() => {
    if (DetectObjectsEnable) {
      let isMounted = true;

      const loadModel = async () => {
        try {
          setLoading(true);
          await tf.setBackend('webgl');
          const loadedModel = await cocossd.load({
            base: 'lite_mobilenet_v2'
          });
          if (isMounted) {
            setModel(loadedModel);
            setError(null);
          }
        } catch (err) {
          console.error('Failed to load model:', err);
          if (isMounted) {
            setError('模型載入失敗');
          }
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      };

      loadModel();
      return () => {
        isMounted = false;
      };
    }
  }, [DetectObjectsEnable]);

  const drawPredictions = useCallback((predictions) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = imgRef.current;

    if (!canvas || !ctx || !img) return;

    if (canvas.width !== img.width || canvas.height !== img.height) {
      canvas.width = img.width;
      canvas.height = img.height;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (predictions && predictions.length > 0) {
      predictions.forEach((prediction) => {
        if (prediction.score > 0.5) {
          const [x, y, width, height] = prediction.bbox;

          ctx.strokeStyle = '#00FF00';
          ctx.lineWidth = 2;
          ctx.strokeRect(x, y, width, height);

          const translatedClass = translateClass(prediction.class);
          const label = `${translatedClass} ${Math.round(prediction.score * 100)}%`;
          ctx.font = '16px Arial';
          const textWidth = ctx.measureText(label).width + 10;

          let labelX = x + width;
          let labelY = y + height / 2;

          if (labelX + textWidth > canvas.width) {
            labelX = x - textWidth;
          }

          if (labelX < 0) {
            labelX = x + 2;
          }

          if (labelY < 20) {
            labelY = 20;
          } else if (labelY > canvas.height - 10) {
            labelY = canvas.height - 10;
          }

          ctx.fillStyle = 'rgba(0, 255, 0, 0.3)';
          ctx.fillRect(labelX, labelY - 10, textWidth, 20);

          ctx.fillStyle = '#000000';
          ctx.fillText(label, labelX + 5, labelY + 5);
        }
      });
    }
  }, []);

  useEffect(() => {
    const img = imgRef.current;
    if (!img) return;

    const throttledDetectObjects = throttle(detectObjects, 1000);

    img.crossOrigin = 'anonymous';

    const handleImageError = () => {
      setLoading(false);
    };

    const handleImageLoad = () => {
      setLoading(false);
      setError(null);
      if (DetectObjectsEnable) {
        throttledDetectObjects();
      }
    };

    img.addEventListener('error', handleImageError);
    img.addEventListener('load', handleImageLoad);

    return () => {
      img.removeEventListener('error', handleImageError);
      img.removeEventListener('load', handleImageLoad);
    };
  }, [detectObjects, DetectObjectsEnable]);

  useEffect(() => {
    return () => {
      if (detectionInterval) {
        clearInterval(detectionInterval);
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [detectionInterval]);

  const translateClass = (className) => {
    return classNameMapping[className.toLowerCase()] || className;
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <img
        ref={imgRef}
        alt="MJPEG Stream"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          display: isPlaying ? 'block' : 'none'
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none'
        }}
      />
    </div>
  );
};

export default LiveImageCanvas;
