import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography } from '@mui/material';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import { userLogout } from '../../actions';
//
import sidebarConfig from './SidebarConfig';
import account from '../../_mocks_/account';
import locationIcon from '../../assets/images/location.png';
import logoIcon from '../../assets/images/logo-leon-black.png';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const RootStyleDashboard = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xxl')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname, isOpenSidebar, onCloseSidebar]);

  const handleLogout = () => {
    dispatch(userLogout({}));
    window.location.href = '/login';
  };
  const getSidebarConfig = () => {
    let titles = [];
    if (usersStore?.users?.user_group_id === 3) {
      titles = ['即時監控', '設備管理', '統計分析'];
    } else titles = ['即時監控', '設備管理', '統計分析', '車輛管理', '帳號管理'];

    return sidebarConfig(titles);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      {/* Sidebar 頂部區域，包含圖標和用戶信息 */}
      <Box
        sx={{
          px: 2.5,
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* 上半部分 */}
        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: 1 }}>
          {/* 點擊後跳轉 URL 的 Logo 圖標 */}
          <a href="/Dashboard">
            <img
              src={logoIcon}
              alt="Logo Icon"
              style={{ width: '175px', height: '50px', marginRight: '5px' }}
            />
          </a>
          {/* 機關名稱 */}
          {/* <Box
            component={RouterLink}
            to="#"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              textDecoration: 'none' // 移除底線
            }}
          >
            <Typography variant="h6" sx={{ color: 'black !important', whiteSpace: 'nowrap' }}>
              {account.organization}
            </Typography>
            <Typography variant="caption" sx={{
              color: 'black !important',
              whiteSpace: 'nowrap',
              fontSize: '0.38rem',
              lineHeight: 0.5
            }}>
              {account.organizationen}
            </Typography>
          </Box> */}
        </Box>
        {/* 下半部分 - 用戶名稱 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'black !important',
              whiteSpace: 'nowrap',
              width: '100%',
              letterSpacing: '0.2em'
            }}
          >
            {account.displayName}
          </Typography>
        </Box>
      </Box>
      {/* 導航部分 */}
      <NavSection navConfig={getSidebarConfig()} />
      {/* 占位符，用於填充剩餘空間 */}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  return (
    <RootStyle>
      {/* 在大螢幕以上顯示的側邊欄 */}
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {/* 渲染側邊欄內容 */}
          {renderContent}
          {/* 底部區域，包含登出按鈕 */}
          <Box sx={{ p: 2, pt: 1.5 }} style={{ backgroundColor: '#7ed4a6' }}>
            <Button
              onClick={handleLogout}
              fullWidth
              color="inherit"
              variant="outlined"
              style={{ backgroundColor: '#00000000', color: '#FFF', fontSize: '20px' }}
            >
              登出
            </Button>
          </Box>
        </Drawer>
      </MHidden>

      {/* 在大螢幕以下顯示的側邊欄 */}
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {/* 渲染側邊欄內容 */}
          {renderContent}
          {/* 底部區域，包含登出按鈕 */}
          <Box sx={{ p: 2, pt: 1.5 }} style={{ backgroundColor: '#7ed4a6' }}>
            <Button
              onClick={handleLogout}
              fullWidth
              color="inherit"
              variant="outlined"
              style={{ backgroundColor: '#00000000', color: '#FFF', fontSize: '20px' }}
            >
              登出
            </Button>
          </Box>
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
