/* eslint-disable consistent-return */
/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap, Polyline } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import SpeedLegend from './SpeedLegend';
import { RoutePopup, CCTVPopup, ReaderPopup } from './PopupContents';

// 確保設置 Leaflet 默認圖標
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function SetBoundsToMarkers({ markers }) {
  const map = useMap();
  React.useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(markers.map((m) => [m.latitude, m.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [map, markers]);

  return null;
}

// 自定義圖標
const cctvOnIcon = new L.Icon({
  iconUrl: '/static/icons/img/icon-camera.svg',
  popupAnchor: [1, -48],
  iconSize: [39, 48],
  iconAnchor: [19, 48]
});
const cctvOffIcon = new L.Icon({
  iconUrl: '/static/icons/img/icon-camera-alert.svg',
  popupAnchor: [1, -48],
  iconSize: [39, 48],
  iconAnchor: [19, 48]
});

// 自定義圖標
const readerOnIcon = new L.Icon({
  iconUrl: '/static/icons/img/icon-map-rfid-reader.svg',
  popupAnchor: [1, -48],
  iconSize: [39, 48],
  iconAnchor: [19, 48]
});

const readerOffIcon = new L.Icon({
  iconUrl: '/static/icons/img/icon-map-rfid-reader-alert.svg',
  popupAnchor: [1, -48],
  iconSize: [39, 48],
  iconAnchor: [19, 48]
});

// 修改 TableControl 組件
const TableControl = ({ cctvData, onCCTVClick, onStreamSelect }) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '4px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th
              style={{
                padding: '8px',
                borderBottom: '2px solid #ddd',
                fontSize: '16px',
                textAlign: 'left',
                width: '80px'
              }}
            >
              狀態
            </th>
            <th
              style={{
                padding: '8px',
                borderBottom: '2px solid #ddd',
                fontSize: '16px',
                textAlign: 'left'
              }}
            >
              名稱
            </th>
          </tr>
        </thead>
        <tbody>
          {cctvData &&
            cctvData.map((cctv) => (
              <tr
                key={cctv.id}
                onClick={() => {
                  onStreamSelect(cctv);
                  onCCTVClick(cctv.latitude, cctv.longitude);
                }}
                style={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f5f5f5' }
                }}
              >
                <td
                  style={{
                    padding: '12px 8px',
                    borderBottom: '1px solid #ddd',
                    textAlign: 'center'
                  }}
                >
                  <img
                    src={
                      cctv.status === 'online'
                        ? '/static/icons/img/icon-camera.svg'
                        : '/static/icons/img/icon-camera-alert.svg'
                    }
                    alt={cctv.status}
                    style={{ width: '24px', height: '24px' }}
                  />
                </td>
                <td
                  style={{
                    padding: '12px 8px',
                    borderBottom: '1px solid #ddd',
                    fontSize: '16px'
                  }}
                >
                  {cctv.name || cctv.id}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

// 新增一個組件來處理地圖交互
const MapInteractionHandler = ({ cctvData }) => {
  const map = useMap();
  const handleCCTVClick = (lat, lng) => {
    map.setView([lat, lng], 18);
  };

  return null;
};

const MapComponent = ({ readers, cctvData, onStreamSelect, routes, speedColors, trafficflow }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleCCTVClick = (lat, lng) => {
    setSelectedLocation([lat, lng]);
  };

  const [travelSpeed, setTravelSpeed] = useState(0);
  const [travelTime, setTravelTime] = useState('0分0秒');

  // 加入 useEffect 監聽 trafficflow 並處理預設值
  useEffect(() => {
    // 處理速度 - 如果無值則預設為 0
    setTravelSpeed(trafficflow?.averageSpeed ?? 0);

    // 處理時間 - 如果無值則預設為 "0分0秒"
    setTravelTime(trafficflow?.travelTime?.formatted ?? '0分0秒');
  }, [trafficflow]);
  const center = useMemo(() => [23.130772, 120.270394], []);
  const getColorBySpeed = (speed, tempspeedColors) => {
    // 無資料時顯示灰色
    if (!speed && speed !== 0) return '#808080';

    // 確保 speedColors 存在且格式正確
    if (
      !tempspeedColors ||
      !tempspeedColors.ranges ||
      !Array.isArray(tempspeedColors.ranges) ||
      !tempspeedColors.ranges.every(
        (range) =>
          range &&
          typeof range === 'object' &&
          typeof range.min === 'number' &&
          (range.max === null || typeof range.max === 'number') &&
          typeof range.color === 'string'
      )
    ) {
      return '#808080';
    }

    // 尋找符合速度範圍的顏色
    const colorRange = tempspeedColors.ranges.find((range) => {
      if (range.max === null) {
        return speed >= range.min;
      }
      return speed >= range.min && speed <= range.max;
    });

    return colorRange ? colorRange.color : '#808080';
  };
  // 使用 useMemo 組合路線資料
  const combinedRoutes = useMemo(() => {
    if (!routes) return [];

    return routes.map((route) => ({
      name: route.name || `路線${route.id}`,
      coordinates: route.coordinates,
      color: getColorBySpeed(trafficflow?.averageSpeed, speedColors),
      flow: trafficflow ? trafficflow.recordCount : 0,
      speed: trafficflow ? trafficflow.averageSpeed : 0
    }));
  }, [routes, trafficflow, speedColors]);
  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative', display: 'flex' }}>
      <Box sx={{ width: '66.66%', height: '100%' }}>
        <MapContainer center={center} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* 其他現有組件 */}
          {/* 圖例 */}
          {/* 渲染路線 */}
          {combinedRoutes.map((route, index) => {
            return (
              <Polyline
                key={index}
                positions={route.coordinates}
                pathOptions={{
                  // 使用 pathOptions 來設定樣式
                  color: route.color,
                  weight: 20
                }}
                // 直接設定 style 屬性作為備選方案
                style={{
                  color: route.color
                }}
              >
                <Popup>
                  <RoutePopup name={route.name} travelTime={travelTime} travelSpeed={travelSpeed} />
                </Popup>
              </Polyline>
            );
          })}
          {cctvData &&
            cctvData.map((cctv) => (
              <Marker
                key={cctv.id}
                position={[cctv.latitude, cctv.longitude]}
                icon={cctv.status === 'online' ? cctvOnIcon : cctvOffIcon}
              >
                <Popup>
                  <CCTVPopup cctv={cctv} onStreamSelect={onStreamSelect} />
                </Popup>
              </Marker>
            ))}
          <SetBoundsToMarkers markers={cctvData || []} />
          {readers &&
            readers.map((reader) => (
              <Marker
                key={reader.id}
                position={[reader.latitude, reader.longitude]}
                icon={reader.status === 1 ? readerOnIcon : readerOffIcon}
              >
                <Popup>
                  <ReaderPopup reader={reader} />
                </Popup>
              </Marker>
            ))}
          <MapInteractionHandler cctvData={cctvData} />
          {selectedLocation && (
            <SetBoundsToMarkers
              markers={[{ latitude: selectedLocation[0], longitude: selectedLocation[1] }]}
            />
          )}
        </MapContainer>
      </Box>

      <Box sx={{ width: '33.33%', height: '100%', overflow: 'hidden' }}>
        <TableControl
          cctvData={cctvData}
          onCCTVClick={handleCCTVClick}
          onStreamSelect={onStreamSelect}
        />
      </Box>
    </Box>
  );
};

export default MapComponent;
