import React, { useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import moment from 'moment';
import MapComponent from '../components/MapComponent';
import LiveTrafficImage from '../components/LiveTrafficImage';
import { getCctvList } from '../services/cctvService';
import { getReaderList } from '../services/readerService';
import {
  getRouteInfo,
  getRouteList,
  getSpeedColors,
  getTrafficFlow
} from '../services/routeService'; // 導入服務

import AnomalyDetection from '../components/AnomalyDetection';
import CctvImageList from '../components/CctvImageList';
import DailyVehicleDetectionChart from '../components/DailyVehicleDetectionChart';
import DailyReaderTrafficChart from '../components/DailyReaderTrafficChart';
import DailyRouteStatsChart from '../components/DailyRouteStatsChart';
import { apiIP } from '../config/ipconfig';

const AnalysisDashboard = () => {
  const [cctvData, setCctvData] = useState([]);
  const [readers, setReaders] = useState([]);
  const [selectedCctv, setSelectedCctv] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [routes, setRoutes] = useState([]); // 新增路由狀態
  const [speedColors, setspeedColors] = useState([]); // 新增路況顏色
  const [trafficflow, settrafficflow] = useState([]); // 新增最近30分鐘路況
  const [tabValue, setTabValue] = useState(0);
  const [cctvDate, setCctvDate] = useState(moment().format('YYYY-MM-DD'));
  const [readerDate, setreaderDate] = useState(moment().format('YYYY-MM-DD'));
  const [routeDate, setRouteDate] = useState(moment().format('YYYY-MM-DD'));
  const [routeInfo, setRouteInfo] = useState([]);
  const [images, setImages] = useState([]);

  const POLLING_INTERVAL = 3 * 60 * 1000; // 3分鐘轉換為毫秒

  // 定義標籤設定物件
  const TAB_CONFIG = {
    cctv: {
      id: 0,
      getLabelText: (date) => `每日攝影機偵測次數統計 (${date})`
    }
    // reader: {
    //   id: 1,
    //   getLabelText: (date) => `每日讀取器車流量統計 (${date})`
    // },
    // route: {
    //   id: 2,
    //   getLabelText: (date) => `每日路段旅行速度與時間統計 (${date})`
    // }
  };
  // 共用樣式設定
  const TAB_STYLES = {
    fontSize: '0.9rem',
    fontWeight: 'medium',
    whiteSpace: 'nowrap'
  };

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const routeData = await getRouteList();
        setRoutes(routeData);
        const speedColorsData = await getSpeedColors();
        setspeedColors(speedColorsData);

        const TrafficFlowData = await getTrafficFlow();
        settrafficflow(TrafficFlowData);

        const routeInfoData = await getRouteInfo();
        setRouteInfo(routeInfoData);
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    };

    const fetchReaders = async () => {
      try {
        const response = await getReaderList();
        if (response.resultCode === 'Success') {
          setReaders(response.rows);
        }
      } catch (error) {
        console.error('Error fetching reader list:', error);
      }
    };

    // 初次執行
    fetchRoutes();
    fetchReaders();

    // 設定定時器
    const routesInterval = setInterval(() => {
      fetchRoutes();
      fetchReaders();
    }, POLLING_INTERVAL);

    // 清理定時器
    return () => clearInterval(routesInterval);
  }, []);

  useEffect(() => {
    const fetchCctvData = async () => {
      try {
        const data = await getCctvList();
        setCctvData(data);
      } catch (error) {
        console.error('Error fetching CCTV data:', error);
      }
    };

    // 初次執行
    fetchCctvData();

    // 設定定時器
    const cctvInterval = setInterval(fetchCctvData, POLLING_INTERVAL);

    // 清理定時器
    return () => clearInterval(cctvInterval);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStreamSelect = (cctvInfo) => {
    setSelectedCctv(cctvInfo);
    setSelectedImage(null);
  };

  const handleImageSelect = (image) => {
    const src = `https://${apiIP}/api/v1/cctv/images/${image.location}/${image.camera}/${image.date}/original/${image.filename}`;
    setSelectedImage({ ...image, src });
  };

  const handleCCTVDateChange = (newDate) => {
    setCctvDate(moment(newDate).format('YYYY-MM-DD'));
  };
  const handlereaderDateChange = (newDate) => {
    setreaderDate(moment(newDate).format('YYYY-MM-DD'));
  };
  const handleRouteStatsDateChange = (newDate) => {
    setRouteDate(moment(newDate).format('YYYY-MM-DD'));
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        {/* 區塊二：每日車輛偵測統計 */}
        <Grid item xs={12}>
          <Paper sx={{ height: '600px', p: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="車輛偵測統計">
                {Object.entries(TAB_CONFIG).map(([key, config]) => {
                  const getDateByKey = (key) => {
                    switch (key) {
                      case 'cctv':
                        return cctvDate;
                      case 'reader':
                        return readerDate;
                      case 'route':
                        return routeDate;
                      default:
                        return routeDate;
                    }
                  };

                  return (
                    <Tab
                      key={config.id}
                      label={config.getLabelText(getDateByKey(key))}
                      sx={TAB_STYLES}
                    />
                  );
                })}
              </Tabs>
            </Box>

            {tabValue === 0 && (
              <DailyVehicleDetectionChart
                cctvData={cctvData}
                date={cctvDate}
                handleDateChange={handleCCTVDateChange}
              />
            )}
            {tabValue === 1 && (
              <DailyReaderTrafficChart
                readerData={readers}
                date={readerDate}
                handleDateChange={handlereaderDateChange}
              />
            )}
            {tabValue === 2 && (
              <DailyRouteStatsChart
                routeData={routeInfo}
                date={routeDate}
                handleDateChange={handleRouteStatsDateChange}
              />
            )}
          </Paper>
        </Grid>

        {/* 區塊四：歷史資料查詢和放大圖片 */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <Typography variant="h6" sx={{ minWidth: 'fit-content' }}>
                歷史資料查詢
              </Typography>
              <FormControl sx={{ minWidth: 300 }}>
                <InputLabel id="cctv-select-label">選擇攝影機</InputLabel>
                <Select
                  labelId="cctv-select-label"
                  value={selectedCctv ? selectedCctv.id : ''}
                  onChange={(e) => {
                    const selected = cctvData.find((cctv) => cctv.id === e.target.value);
                    if (selected) handleStreamSelect(selected);
                  }}
                  label="選擇攝影機"
                  sx={{ backgroundColor: 'white' }}
                >
                  {cctvData.map((cctv) => (
                    <MenuItem key={cctv.id} value={cctv.id}>
                      {cctv.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {' '}
                {/* 將左側歷史查詢區塊佔滿整個容器, 原先md=8 */}
                <Box sx={{ height: '700px', overflow: 'auto' }}>
                  {' '}
                  {/* 調整高度為700px */}
                  <CctvImageList
                    selectedCctv={selectedCctv}
                    onImageSelect={handleImageSelect}
                    images={images}
                    setImages={setImages}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: 'none' }}>
                {' '}
                {/* 隱藏右側圖片顯示部分 */}
                <Box
                  sx={{
                    height: '700px' /* 同樣調整這裡的高度以匹配左側區塊 */,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                  }}
                >
                  <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center' }}>
                    {selectedImage ? '放大圖片' : '選擇圖片'}
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {selectedImage ? (
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: 'Selected CCTV',
                            isFluidWidth: true,
                            src: selectedImage.src
                          },
                          largeImage: {
                            src: selectedImage.src,
                            width: 1280,
                            height: 720
                          },
                          enlargedImageContainerDimensions: {
                            width: '200%',
                            height: '200%'
                          }
                        }}
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      />
                    ) : (
                      <Typography variant="body1">請從左側列表選擇一張圖片</Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalysisDashboard;
