import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Button, Input,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
  Stack,
  Card,
  Table,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Snackbar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog
} from '@mui/material';
import L from 'leaflet';
import readXlsxFile from 'read-excel-file'
import MuiAlert from '@mui/material/Alert';
import { gridColumnsSelector } from '@material-ui/data-grid';
import Page from '../components/Page';
import Label from '../components/Label';
import SearchNotFound from '../components/SearchNotFound';
// eslint-disable-next-line import/named

import { fetchOverviewList } from "../actions";
import TTable from '../components/TTable'
import * as ipconfig from "../config/ipconfig";
import * as config from "../config/config";


const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));


// 定義切換頁
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};








export default function Uploadexcel() {

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '序號'
    },
    {
      id: 'msg',
      numeric: false,
      disablePadding: true,
      fillter: true,
      label: '說明'
    },
    {
      id: 'success',
      numeric: false,
      disablePadding: true,
      fillter: false,
      label: '更新狀態',
      type: 'format',
      fn: (success) => {
        const text = success ? '成功' : '失敗'
        return (
          text
        )
      }
    }
  ];

  const [result, setresult] = React.useState([]);
  const [Vehiclelist, setVehiclelist] = React.useState([]);
  const leonTagsStore = useSelector((state) => state.leonTagManage);
  const { leonTags, error } = leonTagsStore;
  useEffect(() => {

  });
  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = async (e) => {
    setFile(e.target.files[0]);

    // console.dir(ipconfig.apiIP)
    let array = []
    readXlsxFile(e.target.files[0]).then(async (rows) => {

      const header = rows.shift()
      let error = false;
      if (header.length !== 10) {
        error = true
        alert('欄位數量錯誤')
      }
      const key = ['編號', 'EPC', '公司', '車牌', '重量', '高度', '廠牌', '種類', '顏色', '出廠']
      for (let i = 0; i < 10; i += 1) {
        if (!header[i].includes(key[i])) {
          error = true
          alert(`第${(i + 1)}欄應為${key[i]}請更正${header[i]}後重新上傳`)
        }
      }

      if (!error) {
        // 欄位正確檢查內容
        await axios
          .post(`https://${ipconfig.apiIP}/api/v1/ExecelUploadRoute`, { array: rows })
          .then(async (response) => {
            // eslint-disable-next-line eqeqeq
            if (response.data.resultCode === "Success") {
              // console.dir(response.data)
              setresult(response.data.resposearray)

            }
          })
      }



      array = rows
      // await axios
      //       .post(`https://${ipconfig.apiIP}/api/v1/ExecelUploadRoute`, {array:  rows })
      //       .then(async (response) => {                
      //           // eslint-disable-next-line eqeqeq
      //           if (response.data.resultCode === "Success") {
      //               console.dir(response.data)
      //           }
      //       })



      // const Xtf =new Xlsxtranfrom(rows)
      // const teamsql = Xtf.teamsql()
      // console.dir(teamsql)
      // `rows` is an array of rows
      // each row being an array of cells.
    })
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();




    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <Page title="智慧管理監控平台">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button variant="contained" size="large"
            onClick={() => { window.open(`https://${ipconfig.webip}/static/example.xlsx`, '_blank'); }}
          >範例檔案</Button>
          <Input accept="*.xlsx" id="contained-button-file" multiple type="file" onChange={handleOnChange} />

        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <div style={{ color: '#ff7600', 'font-size': 20 }} >
            ※上傳資料第一行不得修改，編號、EPC、車牌不得重複，日期請按照西元年格式並補零如:2022-08-01填入
          </div>
        </Stack>

        <Grid item xs={12}
          sx={{
            '& th': {
              fontSize: config.tableTitleFontSize
            },
            '& td': {
              fontSize: config.tableContentFontSize
            },
            '& span': {
              fontSize: config.tableContentFontSize,
              height: config.tableSpanHeight
            }
          }}>
          <TTable Vehiclelist={Vehiclelist} headCells={headCells} />
        </Grid>
      </Container>
    </Page>
  );
}
