import React, { useState, useEffect, memo } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import LiveImageCanvas from './LiveImageCanvas';

const LiveTrafficImage = memo(({ cctvInfo }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    console.log('cctvInfo:', cctvInfo);
  }, [cctvInfo?.dns_url]);

  return (
    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ p: 1 }}>
        {cctvInfo?.name || '請選擇攝影機'}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: '#e0e0e0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {loading && <CircularProgress sx={{ position: 'absolute' }} />}
        <LiveImageCanvas
          cctvInfo={cctvInfo}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          setLoading={setLoading}
          setError={setError}
          setMessage={setMessage}
          // DetectObjectsEnable
        />
        {error && <Typography color="error">{error}</Typography>}
        {message && (
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center'
            }}
          >
            {message}
          </Typography>
        )}
        {!cctvInfo && (
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center'
            }}
          >
            請在地圖上選擇一個攝影機
          </Typography>
        )}
      </Box>
    </Box>
  );
});

LiveTrafficImage.displayName = 'LiveTrafficImage';

export default LiveTrafficImage;
