import React from 'react';
import { Paper, Typography, Grid } from '@mui/material'; // https://materialui.co/colors
const colorMap = {
  0: '#FFCDD2', // 100 red
  1: '#E1BEE7', // 100 purple
  2: '#C5CAE9', // 100 indigo
  3: '#B3E5FC', // 100 light blue
  4: '#B2DFDB', // 100 teal
  5: '#DCEDC8', // 100 light green
  6: '#FFF9C4', // 100 yellow
  7: '#FFE0B2', // 100 orange
  8: '#F5F5F5'  // 100 gray
};

const AnomalyCard = ({ location, count, detectionType, color }) => (
  <Paper
    elevation={3}
    sx={{
      p: 2,
      textAlign: 'center',
      bgcolor: colorMap[color] || '#e6e6fa', // 根據color設置顏色
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100px' // 確保最小高度
    }}
  >
    <Typography variant="h4" sx={{ mb: 1 }}>
      {count}
    </Typography>
    <Typography variant="body2" sx={{ fontSize: '0.8rem', lineHeight: 1.5, mb: 0.1 }}>
      {location}
    </Typography>
    <Typography variant="body2" sx={{ fontSize: '0.8rem', lineHeight: 1.5 }}>
      {detectionType}
    </Typography>
  </Paper>
);

const AnomalyDetection = ({ cctvData }) => (
  <Paper sx={{ p: 2, mb: 3 }}>
    <Typography variant="h6" gutterBottom>
      前一日CMS觸發次數統計
    </Typography>
    <Grid container spacing={3} sx={{ justifyContent: 'flex-start' }}>
      {cctvData.map((cctv, index) => (
        <Grid item xs={3} key={index}>
          <AnomalyCard
            detectionType={cctv.object_type}
            location={cctv.name}
            count={cctv.event_count || 0}
            color={index}
          />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

export default AnomalyDetection;
